let _env = null;

const envStyleMap = {
  development: "background: dodgerblue; color: white;",
  test: "background: black; color: yellow;",
  production: "background: green; color: white;",
  storybook: "background: #EC5885; color: white;",
};

const env = () => {
  if (!_env) {
    const urlParams = new URLSearchParams(window.location.search);
    _env = urlParams.get("env");

    if (
      !_env ||
      ["development", "test", "production", "storybook"].indexOf(_env) === -1
    ) {
      _env = process.env.NODE_ENV;
    }

    // ensure storybook if in :6006 or /storybook
    if (
      _env !== "storybook" &&
      (window.location.port === "6006" ||
        window.location.pathname.startsWith("/storybook"))
    ) {
      _env = "storybook";
    }

    if (_env !== "production") {
      console.log(`%c ${_env} `, envStyleMap[_env]);
    }
  }

  return _env;
};

export const isDevelopment = () => env() === "development";
export const isStorybook = () => env() === "storybook";
export const isTest = () => env() === "test";
export const isProduction = () => env() === "production";
