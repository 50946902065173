export const onRouteUpdate = (ref, pluginOptions) => {
  const location = ref.location;

  if (pluginOptions === void 0) {
    pluginOptions = {};
  }

  if (process.env.NODE_ENV !== "production" || typeof gtag !== "function") {
    return null;
  }

  const pathIsExcluded =
    location &&
    typeof window.excludeGAPaths !== "undefined" &&
    window.excludeGAPaths.some(function(rx) {
      return rx.test(location.pathname);
    });
  if (pathIsExcluded) return null; // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299

  const sendPageView = () => {
    var pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;
    window.gtag("set", "page_path", pagePath);
    window.gtag("event", "page_view");
  }; // Minimum delay for reactHelmet's requestAnimationFrame

  const delay = Math.max(32, pluginOptions.pageTransitionDelay || 0);
  setTimeout(sendPageView, delay);
  return null;
};
