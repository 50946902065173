const sendJobSubmission = apiFetch => async ({
  name,
  email,
  companyName,
  typology,
  budgetMin,
  budgetMax,
  note,
  recaptcha,
}) => {
  try {
    var res = await apiFetch({
      endpoint: "forms/business",
      method: "post",
      body: JSON.stringify({
        name,
        email,
        companyName,
        typology,
        budgetMin,
        budgetMax,
        note,
        recaptcha,
      }),
      okStatus: 200,
      headers: {
        "Content-Type": "application/json",
      },
    });

    return res.json();
  } catch {
    return false;
  }
};

export default sendJobSubmission;
