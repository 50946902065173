import classnames from "classnames";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import ReactSlider from "react-slider";

import "./style.css";

const RangeField = ({
  minName,
  maxName,
  label,
  required,
  className,
  defaultValue,
  errorMessages,
  formatter,
  ...props
}) => {
  const { register, setValue } = useFormContext();

  const [text, setText] = useState(formatter(defaultValue[0], defaultValue[1]));

  return (
    <div className={classnames("range-field", className)}>
      <label className="range-field__label">{label}</label>

      <div className="range-field__text">{text}</div>

      <ReactSlider
        onChange={value => {
          setText(formatter(value[0], value[1]));
          setValue(minName, value[0]);
          setValue(maxName, value[1]);
        }}
        className="range-field__horizontal-slider"
        thumbClassName="range-field__horizontal-slider-thumb"
        trackClassName="range-field__horizontal-slider-track"
        defaultValue={defaultValue}
        step={10}
        pearling
        minDistance={10}
      />

      <input type="hidden" id={minName} {...register(minName)} />
      <input type="hidden" id={maxName} {...register(maxName)} />
    </div>
  );
};

export default RangeField;
