import React from "react";
import "./style.css";

const Submit = ({ value, disabled: isDisabled }) => (
  <button type="submit" className="submit" disabled={isDisabled}>
    {value}
  </button>
);

export default Submit;
