import classnames from "classnames";
import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";

const Container = ({
  children,
  className,
  fluid: isFluid,
  narrowMargins: hasNarrowMargins,
  test,
}) => {
  const ref = useRef(null);

  const columnSizeRef = useRef(null);

  const [_, setColumnSize] = useState(null);

  // calculate initial columnSize on mount before first render
  useMemo(() => {
    if (typeof window === "undefined") {
      return;
    }

    // create .grid element
    const grid = document.createElement("div");
    grid.classList.add("grid");
    if (hasNarrowMargins) {
      grid.classList.add("grid--narrow-margins");
    }
    grid.style.display = "none";
    document.body.appendChild(grid);

    // read css variables
    const computedStyle = window.getComputedStyle(grid);
    const gutterSize = parseInt(
      computedStyle.getPropertyValue("--grid-gutter") ?? "20"
    );
    const marginSize = parseInt(
      computedStyle.getPropertyValue("--grid-margin") ?? "20"
    );
    const columns = parseInt(
      computedStyle.getPropertyValue("--grid-columns") ?? "4"
    );

    const baseWidth = isFluid
      ? window.innerWidth
      : Math.min(window.innerWidth, 1280);
    const columnSize = `${(baseWidth -
      marginSize * 2 -
      (columns - 1) * gutterSize) /
      columns}px`;

    // set columnSizeRef
    columnSizeRef.current = columnSize;

    // remove .grid element
    document.body.removeChild(grid);
  }, []);

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return null;
    }
    const computedStyle = window.getComputedStyle(ref.current);
    const gutterSize = parseInt(
      computedStyle.getPropertyValue("--grid-gutter") ?? "20"
    );
    const marginSize = parseInt(
      computedStyle.getPropertyValue("--grid-margin") ?? "20"
    );
    const columns = parseInt(
      computedStyle.getPropertyValue("--grid-columns") ?? "4"
    );

    const baseWidth = isFluid
      ? ref.current.offsetWidth
      : Math.min(ref.current.offsetWidth, 1280);

    const columnSize = `${(baseWidth -
      marginSize * 2 -
      (columns - 1) * gutterSize) /
      columns}px`;

    columnSizeRef.current = columnSize;
    setColumnSize(columnSize);
  }, [isFluid, hasNarrowMargins]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [isFluid, hasNarrowMargins]);

  return (
    <div
      ref={ref}
      className={classnames(
        "grid",
        {
          "grid--fluid": isFluid,
          "grid--narrow-margins": hasNarrowMargins,
        },
        className
      )}
      style={{
        // set css variables
        "--grid-column": columnSizeRef.current,
      }}
    >
      {children}
    </div>
  );
};

export default Container;
