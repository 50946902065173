import classnames from "classnames";
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useFormContext } from "react-hook-form";

import "./style.css";

const defaultErrorMessages = {
  required: "Required field.",
  other: "Invalid field.",
};

const TextareaField = ({
  name,
  label,
  required,
  rows = 4,
  className,
  defaultValue,
  errorMessages,
  ...props
}) => {
  const fieldRef = useRef(null);

  const [value, setValue] = useState(defaultValue);
  const [hasFocus, setHasFocus] = useState(false);
  const handleBlur = useCallback(e => {
    setHasFocus(false);
  }, []);
  const handleFocus = useCallback(e => {
    setHasFocus(true);
  }, []);
  const handleChange = useCallback(e => {
    setValue(e.target.value);
  }, []);

  useEffect(() => {
    fieldRef.current.addEventListener("focus", handleFocus);
    fieldRef.current.addEventListener("blur", handleBlur);

    return () => {
      fieldRef.current.removeEventListener("focus", handleFocus);
      fieldRef.current.removeEventListener("blur", handleBlur);
    };
  }, [handleFocus, handleBlur]);

  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { ref, ...inputProps } = register(name, {
    required,
    defaultValue,
    onChange: handleChange,
  });

  const error = useMemo(() => {
    const messages = { ...defaultErrorMessages, ...errorMessages };
    if (errors[name]) {
      return messages[errors[name].type] || messages.other;
    }

    return null;
  }, [errors, name, defaultErrorMessages, errorMessages]);

  return (
    <div className={classnames("textarea-field", className)}>
      <label
        className={classnames("textarea-field__label", {
          "textarea-field__label--zoomed": !value && !hasFocus,
        })}
        htmlFor={name}
      >
        {label}
      </label>

      <textarea
        ref={r => {
          ref(r);
          fieldRef.current = r;
        }}
        id={name}
        className={classnames("textarea-field__input")}
        rows={rows}
        {...props}
        {...inputProps}
      />

      {error && <div className="textarea-field__error">{error}</div>}
    </div>
  );
};

export default TextareaField;
