import classnames from "classnames";
import React, { useState, useCallback, useEffect } from "react";
import { isProduction } from "@utils/isEnvironment";
import Container from "./Container";
import Row from "./Row";
import Cell from "./Cell";

const DEBUGGER_DISABLED = 0;
const DEBUGGER_ENABLED = 1;
const DEBUGGER_ENABLED_NARROW_MARGINS = 2;
const DEBUGGER_ENABLED_FLUID = 3;
const DEBUGGER_STATES = [
  DEBUGGER_DISABLED,
  DEBUGGER_ENABLED,
  DEBUGGER_ENABLED_NARROW_MARGINS,
  DEBUGGER_ENABLED_FLUID,
];

// NOTE: This component is responsible for defining grid rules for the layout
//       via css variables.
const Debugger = () => {
  const [state, setState] = useState(DEBUGGER_DISABLED);

  const handleKeydown = useCallback(
    event => {
      if (!isProduction() && event.ctrlKey && event.key.toLowerCase() === "g") {
        setState((state + 1) % DEBUGGER_STATES.length);
      }
    },
    [state, setState]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);

    return () => window.removeEventListener("keydown", handleKeydown);
  }, [handleKeydown]);

  console.log("state", state);

  return (
    <div
      className={classnames("grid-debugger", {
        "grid-debugger--active": state !== DEBUGGER_DISABLED,
      })}
    >
      <Container
        fluid={state === DEBUGGER_ENABLED_FLUID}
        narrowMargins={state === DEBUGGER_ENABLED_NARROW_MARGINS}
        test
      >
        <Row>
          <Cell>
            <div className="grid-debugger__stripes"></div>
          </Cell>
        </Row>
      </Container>
    </div>
  );
};

export default Debugger;
