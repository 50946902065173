import React, { useState, useEffect } from "react";
import { Link as SimpleLink } from "gatsby";
import TransitionLink from "gatsby-plugin-transition-link";
import gsap, { Power2 } from "gsap";
import { isStorybook } from "@utils/isEnvironment";

export default function Fade({
  exit,
  entry,
  fade: removedProp,
  duration = 0.8,
  delay = 0,
  ...props
}) {
  const [isStorybookMode, setIsStorybookMode] = useState(false);
  useEffect(() => {
    if (isStorybook()) {
      setIsStorybookMode(true);
    }
  }, [setIsStorybookMode]);
  // check if is in STORYBOOK
  const Link = isStorybookMode ? SimpleLink : TransitionLink;

  return (
    <Link
      exit={{
        length: duration + delay,
      }}
      entry={{
        delay: duration + delay,
      }}
      trigger={async pages => {
        const timeline = gsap.timeline();
        const exit = await pages.exit;

        timeline.fromTo(
          exit.node,
          { opacity: 1 },
          {
            opacity: 0,
            duration,
            delay,
            ease: Power2.easeInOut,
            onComplete: () => {
              window.scrollTo(0, 0);
            },
          }
        );

        const entry = await pages.entry;

        timeline.fromTo(
          entry.node,
          { opacity: 0 },
          {
            opacity: 1,
            duration,
            ease: Power2.easeInOut,
          }
        );
      }}
      {...props}
    >
      {props.children}
    </Link>
  );
}
