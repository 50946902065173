import React from "react";
import classnames from "classnames";

const Cell = ({
  offset,
  mobileOffset,
  columns,
  mobileColumns,
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={classnames(
        "grid__cell",
        {
          [`grid__cell--offset-${offset}`]: !!offset,
          [`grid__cell--mobile-offset-${mobileOffset}`]: !!mobileOffset,
          [`grid__cell--columns-${columns}`]: !!columns,
          [`grid__cell--mobile-columns-${mobileColumns}`]: !!mobileColumns,
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Cell;
