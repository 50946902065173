import classnames from "classnames";
import { Link } from "gatsby";
import React, { Component } from "react";

import logoSprite from "@assets/images/logo-sprite.png";

import "./style.css";

const LOGO_ENTER_ANIMATION = 1;
const LOGO_EXIT_ANIMATION = 2;

class CircularText extends Component {
  constructor(props) {
    super(props);

    this.wordsTransitionDelay = 0.16;

    this.logoCanvas = React.createRef({});
    this.logoCanvasCtx = null;
    this.logoSpriteImage = null;
    this.logoAnimation = {
      raf: null,
      currentFrame: 0,
      enteringFramesCount: 52,
      framesCount: 125,
      millis: 1000 / 60,
      lastRenderTime: 0,
      animationType: null,
      columns: 10,
      tileWidth: 408,
      tileHeight: 408,
    };
  }

  componentDidMount() {
    this.updateWordsPosition();

    if (this.logoCanvas.current) {
      this.setupLogoCanvas();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.playLogoAnimation(LOGO_ENTER_ANIMATION);
    } else if (
      prevProps.visible !== this.props.visible &&
      !this.props.visible
    ) {
      this.playLogoAnimation(LOGO_EXIT_ANIMATION);
    }
  }

  updateWordsPosition = () => {
    const path = document.querySelector("path#circle");
    const items = document.querySelectorAll("a textPath");
    const pathLength = path.getTotalLength();
    let totalWordsLength = 0;

    items.forEach(item => {
      totalWordsLength += item.getComputedTextLength();
    });

    const wordOffset = (pathLength - totalWordsLength) / items.length;
    let pos = 0;

    items.forEach((item, i) => {
      item.setAttribute("startOffset", pos);
      pos += item.getComputedTextLength() + wordOffset;
    });
  };

  onCircularTextTransitionEnd = () => {
    const { onHidden } = this.props;

    if (onHidden) {
      setTimeout(() => {
        onHidden();
      }, 400);
    }
  };

  setupLogoCanvas = () => {
    this.logoCanvasCtx = this.logoCanvas.current.getContext("2d");
    this.logoSpriteImage = new Image();
    this.logoSpriteImage.src = logoSprite;

    // on setup render first frame
    this.renderLogoAnimationFrame(0);
  };

  renderLogoAnimationFrame = (frame = 0) => {
    if (frame < 0 || frame >= this.logoAnimation.framesCount) {
      return;
    }

    const sourceX =
      (frame % this.logoAnimation.columns) * this.logoAnimation.tileWidth;
    const sourceY =
      Math.floor(frame / this.logoAnimation.columns) *
      this.logoAnimation.tileHeight;

    this.logoCanvasCtx.clearRect(
      0,
      0,
      this.logoCanvas.current.width,
      this.logoCanvas.current.height
    );
    this.logoCanvasCtx.drawImage(
      this.logoSpriteImage,
      // source
      sourceX,
      sourceY,
      this.logoAnimation.tileWidth,
      this.logoAnimation.tileHeight,
      // destination
      0,
      0,
      this.logoCanvas.current.width,
      this.logoCanvas.current.height
    );
  };

  animateLogo = t => {
    if (
      this.logoSpriteImage.complete &&
      t - this.logoAnimation.lastRenderTime > this.logoAnimation.millis
    ) {
      const newFrame = this.logoAnimation.currentFrame + 1;

      if (newFrame < 0 || newFrame >= this.logoAnimation.framesCount) {
        // animation complete
        this.logoAnimation.raf = null;
        return;
      }

      if (
        this.logoAnimation.animationType === LOGO_ENTER_ANIMATION &&
        newFrame > this.logoAnimation.enteringFramesCount
      ) {
        // animation complete
        this.logoAnimation.raf = null;
        return;
      }

      this.renderLogoAnimationFrame(newFrame);
      this.logoAnimation.lastRenderTime = t;
      this.logoAnimation.currentFrame = newFrame;
    }

    if (this.logoAnimation.raf) {
      this.logoAnimation.raf = requestAnimationFrame(this.animateLogo);
    }
  };

  playLogoAnimation = (animationType = LOGO_ENTER_ANIMATION) => {
    this.logoAnimation.animationType = animationType;
    if (
      animationType === LOGO_ENTER_ANIMATION &&
      this.logoAnimation.currentFrame > this.logoAnimation.enteringFramesCount
    ) {
      this.logoAnimation.currentFrame = 0;
    } else if (
      animationType === LOGO_EXIT_ANIMATION &&
      this.logoAnimation.currentFrame <= this.logoAnimation.enteringFramesCount
    ) {
      this.logoAnimation.currentFrame =
        this.logoAnimation.enteringFramesCount + 1;
    }

    if (!this.logoAnimation.raf) {
      this.logoAnimation.raf = requestAnimationFrame(this.animateLogo);
    }
  };

  render() {
    const { words, logo, visible } = this.props;

    const circularTextClasses = classnames("circular-text", {
      "circular-text--visible": visible,
    });

    return (
      <div
        className={circularTextClasses}
        onTransitionEnd={this.onCircularTextTransitionEnd}
      >
        {logo && (
          <canvas
            className="logo"
            width="408"
            height="408"
            ref={this.logoCanvas}
          />
        )}

        <svg viewBox="0 0 100 100">
          <path
            id="circle"
            d="
            M50,0
            A50,50 0 0 1 50,100
            A50,50 0 0 1 50,0
            "
          />

          {words &&
            words.map((word, i) => (
              <Link
                className="cursor-type cursor-type--anchor"
                key={word.text}
                to={word.action && word.action}
                activeClassName="active"
                style={{
                  transitionDelay: visible
                    ? `${(0.8 / words.length) * i}s`
                    : `${(0.8 / words.length) * (words.length - 1 - i)}s`,
                }}
                onClick={() => {
                  this.props.onMenuItemClicked &&
                    this.props.onMenuItemClicked();
                }}
              >
                <text>
                  <textPath href="#circle">{word.text && word.text}</textPath>
                </text>
              </Link>
            ))}
        </svg>
      </div>
    );
  }
}

export default CircularText;
