const getLatestInstagrams = apiFetch => async () => {
  try {
    const response = await apiFetch({
      endpoint: "instagrams",
      method: "get",
      okStatus: 200,
    });
    return await response.json();
  } catch {
    return [];
  }
};

getLatestInstagrams.propTypes = {};

export default getLatestInstagrams;
