import PropTypes from "prop-types";

const DEFAULT_OK_STATUS = 200;
const DEFAULT_METHOD = "GET";

const API_URL = `${process.env.API_BASE_URL}/wp-json/api`;

const handleError = error => ({
  code: "internet_connection",
  message: "Please check your internet connection and try again",
  data: {
    error,
  },
});

const handleBadResponse = async response => {
  if (!response) {
    return handleError();
  }

  let code = "internal_server_error";
  let message =
    "Something went wrong while handling your request, please try again";

  if (response.status !== 500) {
    const body = await response.json();
    code = body.code;
    message = body.message;
  }

  return {
    code,
    message,
    data: {
      response,
    },
  };
};

const apiFetch = async ({ endpoint, method, body, okStatus, headers = {} }) => {
  let response;

  const url = `${API_URL}/${endpoint}/`;
  try {
    response = await fetch(url, {
      method: method.toUpperCase(),
      body,
      headers: {
        Accept: "application/json",
        ...headers,
      },
      credentials: "include",
    });
  } catch (error) {
    throw handleError(error);
  }

  if (response.status !== okStatus) {
    throw await handleBadResponse(response);
  }

  return response;
};

apiFetch.propTypes = {
  endpoint: PropTypes.string,
  method: PropTypes.string,
  body: PropTypes.object,
  okStatus: PropTypes.number,
};
apiFetch.defaultProps = {
  method: DEFAULT_METHOD,
  okStatus: DEFAULT_OK_STATUS,
};

export default apiFetch;
