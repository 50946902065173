import PropTypes from "prop-types";

const subscribe = apiFetch => async ({ email }) => {
  try {
    await apiFetch({
      endpoint: "newsletter",
      method: "post",
      body: JSON.stringify({ email }),
      okStatus: 201,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    return { success: false, errorMessage: error.message };
  }
  return { success: true };
};

subscribe.propTypes = {
  email: PropTypes.string,
};

export default subscribe;
