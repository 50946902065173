import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { sendJobSubmission } from "@api";
import Form from "@components/Form";
import InputField from "@components/Form/InputField";
import NewSelectField from "@components/Form/NewSelectField";
import SelectField from "@components/Form/SelectField";
import TextareaField from "@components/Form/TextareaField";
import FileField from "@components/Form/FileField";
import Submit from "@components/Form/Submit";
import Recaptcha from "@components/Form/Recaptcha";

function TheForm({ onSuccess, onError }) {
  const recaptchaRef = useRef(null);
  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false);

  const { reset } = useForm();

  const onSubmit = async data => {
    const recaptcha = await recaptchaRef.current.executeAsync();
    const options = { ...data, recaptcha };

    try {
      const data = await sendJobSubmission(options);

      if (data.success === true) {
        onSuccess();
        reset();
      } else {
        onError();
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <InputField name="name" label="Name*" required maxLength={20} />

      <InputField
        name="email"
        label="Email*"
        required
        pattern={
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Z\-0-9]+\.)+[A-Z]{2,}))$/i
        }
        errorMessages={{
          required: "A valid email is required",
          other: "A valid email is required",
        }}
      />

      <NewSelectField
        name="position"
        label="Position*"
        required
        options={[
          { value: "Visual Designer", label: "Visual Designer" },
          { value: "UI/UX Designer", label: "UI/UX Designer" },
          { value: "Full Stack Developer", label: "Full Stack Developer" },
          { value: "Producer", label: "Producer" },
          { value: "Project Manager", label: "Project Manager" },
          { value: "Copywriter", label: "Copywriter" },
        ]}
        defaultValue="Visual Designer"
      />

      <NewSelectField
        name="typology"
        label="Typology"
        options={[
          { value: "Full-Time", label: "Full Time" },
          { value: "Part-Time", label: "Part Time" },
          { value: "Contract", label: "Contract" },
          { value: "Internship", label: "Internship" },
        ]}
        defaultValue="Full-Time"
      />

      <FileField name="CV" label="CV*" />
      <FileField name="coverletter" label="Cover Letter" />
      <FileField name="portfolio" label="Portfolio" />

      <InputField name="website" label="Website" />
      <InputField name="linkedin" label="Linkedin" />

      <TextareaField name="message" label="Note" rows="4" />

      <Submit disabled={!isRecaptchaReady} value="Send" />

      <Recaptcha ref={recaptchaRef} onReady={() => setIsRecaptchaReady(true)} />

      <p>
        By sending this message you are accepting our{" "}
        <a href="/privacy" target="_blank">
          Privacy and cookies policy
        </a>
      </p>
    </Form>
  );
}

export default TheForm;
