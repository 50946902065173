module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Roll Studio","short_name":"Roll Studio","start_url":"/","background_color":"black","theme_color":"black","display":"minimal-ui","icon":"src/assets/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/roll-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"ipMetaApiKey":"daf8d1c2883d8dd9b46da58f279766e4706d21c680461a1b86a949efa9272ddd","trackingId":"UA-121359-32","head":false,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0,"defer":false,"sampleRate":100,"siteSpeedSampleRate":1},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
