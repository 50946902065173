import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import getCookie from "@utils/getCookie";
import { CSSTransition } from "react-transition-group";
import "./style.css";

import Cookieicon from "@assets/images/cookieicon.png";

function CookiePopup() {
  const [showCookiePopup, setShowCookiePopup] = useState(false);

  const onAgreeClicked = () => {
    document.cookie = "cookieBarAccepted=true;path=/";
    setShowCookiePopup(false);
    //analytics event
    if (window.gtag) {
      window.gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    }
  };

  const notAgreeClicked = () => {
    document.cookie = "cookieNotAgree=true;path=/";
    setShowCookiePopup(false);
  };

  useEffect(() => {
    var cookieBarAccepted = getCookie("cookieBarAccepted");
    var cookieNotAgree = getCookie("cookieNotAgree");

    if (!cookieBarAccepted && !cookieNotAgree) {
      setShowCookiePopup(true);
    } else if (cookieBarAccepted === "true") {
      //analytics event
    }
  }, []);

  return (
    <CSSTransition
      in={showCookiePopup}
      timeout={1000}
      mountOnEnter
      unmountOnExit
    >
      <div className="cookie-popup">
        <div className="cookie-popup__message">
          <img className="cookie-icon" src={Cookieicon} alt="cookie" /> We like
          cookies, so we use cookies <Link to="/privacy"> discover more</Link>
        </div>
        <div className="cookie-popup__buttons">
          <button id="accept-cookie" href="#" onClick={onAgreeClicked}>
            Agree
          </button>
          <button id="close-cookie" href="#" onClick={notAgreeClicked}>
            Close
          </button>
        </div>
      </div>
    </CSSTransition>
  );
}

export default CookiePopup;
