import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

const FileInput = props => {
  const { name, onChange } = props;

  const [files, setFiles] = useState([]);

  const handleDrop = useCallback(
    acceptedFiles => {
      setFiles([...files, ...acceptedFiles]);
      onChange(acceptedFiles);
    },
    [files, onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: handleDrop,
  });

  const handleRemoveAll = () => {
    setFiles([]);
    onChange([]);
  };

  return (
    <>
      {!files.length ? (
        <div
          {...getRootProps({
            className: "file-field__input",
          })}
        >
          <input
            {...getInputProps({
              id: name,
              multiple: false,
            })}
          />
          <div>
            Drag & drop or <u>browse</u>
          </div>
        </div>
      ) : (
        <aside>
          <ul className="file-field__lists">
            {files.map(file => (
              <li key={file.path} className="file-field__list-item">
                <span>{file.path}</span>{" "}
                <button
                  type="button"
                  className="file-field__list-item-remove-button"
                  onClick={handleRemoveAll}
                ></button>
              </li>
            ))}
          </ul>
        </aside>
      )}
    </>
  );
};

export default FileInput;
