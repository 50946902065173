const sendJobSubmission = apiFetch => async ({
  name,
  email,
  position,
  typology,
  CV,
  coverletter,
  portfolio,
  note,
  recaptcha,
}) => {
  const body = new FormData();
  body.append("name", name);
  body.append("email", email);
  body.append("position", position);
  body.append("typology", typology);
  body.append("cv", CV);
  body.append("coverletter", coverletter);
  body.append("portfolio", portfolio);
  body.append("note", note);
  body.append("recaptcha", recaptcha);

  try {
    var res = await apiFetch({
      endpoint: "forms/job",
      method: "post",
      body,
      okStatus: 200,
    });

    return res.json();
  } catch {
    return false;
  }
};

export default sendJobSubmission;
