import classnames from "classnames";
import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import FileInput from "./FileInput";

import "./style.css";

const defaultErrorMessages = {
  required: "Required field.",
  other: "Invalid field.",
};

const FileField = ({
  name,
  label,
  required,
  className,
  errorMessages,
  ...props
}) => {
  const {
    // register,
    formState: { errors },
    control,
  } = useFormContext();

  // const inputProps = register(name, {
  //   required,
  //   defaultValue,
  // });

  const error = useMemo(() => {
    const messages = { ...defaultErrorMessages, ...errorMessages };
    if (errors[name]) {
      return messages[errors[name].type] || messages.other;
    }

    return null;
  }, [errors, name, errorMessages]);

  return (
    <div className={classnames("file-field", className)}>
      <label className="file-field__label" htmlFor={name}>
        {label}
      </label>

      <Controller
        render={({ field: { onChange } }) => (
          <FileInput
            name={name}
            label={label}
            onChange={(acceptedFiles, fileRejections, e) => {
              if (acceptedFiles.length > 0) {
                onChange(acceptedFiles[0]);
              } else {
                onChange(null);
              }
            }}
          />
        )}
        name={name}
        control={control}
        defaultValue=""
      />

      {error && <div className="file-field__error">{error}</div>}
    </div>
  );
};

export default FileField;
