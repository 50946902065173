import React, { Component } from "react";
import { Link } from "gatsby";

import classnames from "classnames";

import CircularText from "@components/CircularText";
import { ReactComponent as Close } from "@assets/images/close.svg";
import { ReactComponent as Logo } from "@assets/images/r-symbol.svg";

import "./style.css";

class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: null,
    };

    this.navVoices = [
      {
        text: "home",
        action: "/",
      },
      {
        text: "work",
        action: "/work",
      },
      {
        text: "about",
        action: "/about",
      },
      {
        text: "careers",
        action: "/careers",
      },
      {
        text: "contact",
        action: "/contact",
      },
    ];
  }

  onClose = () => {
    if (this.state.menuVisible === false) {
      this.props.onClose();
      this.setState({
        menuVisible: null,
      });
    } else {
      this.setState(
        {
          menuVisible: false,
        },
        () => {
          if (window.innerWidth < 1025) {
            setTimeout(this.onUlTransitionEnd, 200);
          }
        }
      );
    }
  };

  onCircularTextHidden = () => {
    const { onClose } = this.props;

    if (onClose && this.state.menuVisible !== null) {
      onClose();
      this.setState({
        menuVisible: null,
      });
    }
  };

  onUlTransitionEnd = () => {
    const { onClose } = this.props;

    if (onClose && this.state.menuVisible !== null) {
      onClose();
      this.setState({
        menuVisible: null,
      });
    }
  };

  render() {
    const { open } = this.props;

    const navClasses = classnames("cursor-color", "cursor-color--white", {
      open: open,
    });

    const ulClasses = classnames("", {
      visible: this.state.menuVisible === null ? open : this.state.menuVisible,
    });

    return (
      <nav className={navClasses}>
        <Link to="/" className="logo">
          <Logo />
        </Link>

        <button className="close" onClick={this.onClose}>
          <Close />
        </button>

        <ul className={ulClasses}>
          {this.navVoices.map(voice => (
            <li key={voice.text}>
              <Link
                to={voice.action}
                activeClassName="active"
                onClick={this.onClose}
              >
                {voice.text}
              </Link>
            </li>
          ))}
        </ul>

        <CircularText
          words={this.navVoices}
          logo={true}
          visible={
            this.state.menuVisible === null ? open : this.state.menuVisible
          }
          onHidden={this.onCircularTextHidden}
          onMenuItemClicked={this.onClose}
        />
      </nav>
    );
  }
}

export default Nav;
