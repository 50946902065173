import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { sendBusinessSubmission } from "@api";
import Form from "@components/Form";
import InputField from "@components/Form/InputField";
import SelectField from "@components/Form/SelectField";
import NewSelectField from "@components/Form/NewSelectField";
import RangeField from "@components/Form/RangeField";
import TextareaField from "@components/Form/TextareaField";
import Submit from "@components/Form/Submit";
import Recaptcha from "@components/Form/Recaptcha";

function TheForm({ onSuccess, onError }) {
  const recaptchaRef = useRef(null);
  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false);

  const { reset } = useForm();

  const onSubmit = async data => {
    const recaptcha = await recaptchaRef.current.executeAsync();
    const options = { ...data, recaptcha };

    try {
      const data = await sendBusinessSubmission(options);
      if (data.success === true) {
        onSuccess();
        reset();
      } else {
        onError();
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <InputField name="name" label="Your Full Name*" required maxLength={20} />

      <InputField
        name="email"
        label="Your Email*"
        required
        pattern={
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Z\-0-9]+\.)+[A-Z]{2,}))$/i
        }
        errorMessages={{
          required: "A valid email is required",
          other: "A valid email is required",
        }}
      />

      <InputField
        name="companyName"
        label="Company Name*"
        required
        maxLength={20}
      />

      <NewSelectField
        name="typology"
        label="Project Typology*"
        required
        options={[
          { value: "Website", label: "Website" },
          { value: "E-commerce", label: "E-commerce" },
          { value: "Web App", label: "Web App" },
          { value: "Mobile App", label: "Mobile App" },
          { value: "Branding", label: "Branding" },
          { value: "Other", label: "Other" },
        ]}
        defaultValue="Website"
      />

      <RangeField
        minName="budgetMin"
        maxName="budgetMax"
        label="Budget"
        defaultValue={[0, 10]}
        formatter={(min, max) => `£ ${min}/${max} K`}
      />

      <TextareaField name="note" label="Note" rows={4} />

      <Submit disabled={!isRecaptchaReady} value="Send" />

      <Recaptcha ref={recaptchaRef} onReady={() => setIsRecaptchaReady(true)} />

      <p>
        By sending this message you are accepting our{" "}
        <a href="/privacy" target="_blank">
          Privacy and cookies policy
        </a>
      </p>
    </Form>
  );
}

export default TheForm;
