import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { sendMail } from "@api";
import Form from "@components/Form";
import InputField from "@components/Form/InputField";
import TextareaField from "@components/Form/TextareaField";
import Submit from "@components/Form/Submit";
import Recaptcha from "@components/Form/Recaptcha";

function TheForm({ onSuccess, onError }) {
  const recaptchaRef = useRef(null);
  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false);

  const { reset } = useForm();

  const onSubmit = async data => {
    const recaptcha = await recaptchaRef.current.executeAsync();
    const options = { ...data, recaptcha };

    try {
      const data = await sendMail(options);

      if (data.success === true) {
        onSuccess();
        reset();
      } else {
        onError();
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <InputField name="name" label="Name*" required maxLength={20} />

      <InputField
        name="email"
        label="Your Email*"
        required
        pattern={
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Z\-0-9]+\.)+[A-Z]{2,}))$/i
        }
        errorMessages={{
          required: "A valid email is required",
          other: "A valid email is required",
        }}
      />

      <TextareaField name="message" label="Message*" rows={4} />

      <Submit disabled={!isRecaptchaReady} value="Send" />

      <Recaptcha ref={recaptchaRef} onReady={() => setIsRecaptchaReady(true)} />

      <p>
        By sending this message you are accepting our{" "}
        <a href="/privacy" target="_blank">
          Privacy and cookies policy
        </a>
      </p>
    </Form>
  );
}

export default TheForm;
