import classnames from "classnames";
import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import "./style.css";

const defaultErrorMessages = {
  required: "Required field.",
  other: "Invalid field.",
};

const SelectField = ({
  name,
  label,
  required,
  className,
  options,
  defaultValue,
  errorMessages,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputProps = register(name, {
    required,
    defaultValue,
  });

  const error = useMemo(() => {
    const messages = { ...defaultErrorMessages, ...errorMessages };
    if (errors[name]) {
      return messages[errors[name].type] || messages.other;
    }

    return null;
  }, [errors, name, defaultErrorMessages, errorMessages]);

  return (
    <div className={classnames("select-field", className)}>
      <label className="select-field__label" htmlFor={name}>
        {label}
      </label>

      <select
        id={name}
        className="select-field__select"
        {...props}
        {...inputProps}
      >
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>

      {error && <div className="select-field__error">{error}</div>}
    </div>
  );
};

export default SelectField;
