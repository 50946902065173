import classnames from "classnames";
import React from "react";

const Row = ({ children, className, ...props }) => {
  return (
    <div className={classnames("grid__row", className)} {...props}>
      {children}
    </div>
  );
};

export default Row;
