import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { ReactComponent as Logo } from "@assets/images/r-symbol.svg";
import { ReactComponent as MenuIcon } from "@assets/images/menu-icon.svg";
import FadeLink from "@components/FadeLink";
import Nav from "@components/Nav";

import "./style.css";

const colorRegex = /rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+).*\)/;

const noop = event => event.preventDefault();

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNav: false,
      navOpened: false,
      inverse: false,
    };

    this.header = createRef();

    this.backgroundEl = null;

    this.scrollY = 0;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scroll);
    window.addEventListener("updateHeaderColor", this.handleUpdateHeaderColor);
    window.addEventListener("checkHeaderColor", this.handleCheckHeaderColor);
    this.scroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scroll);
    window.removeEventListener(
      "updateHeaderColor",
      this.handleUpdateHeaderColor
    );
    window.removeEventListener("checkHeaderColor", this.handleCheckHeaderColor);
  }

  handleCheckHeaderColor = () => {
    this.scroll();
  };
  handleUpdateHeaderColor = ({ detail: color }) => {
    if (!color) {
      return;
    }

    const inverse = color === "light";

    if (inverse !== this.state.inverse) {
      this.setState({ inverse });
    }
  };

  scroll = () => {
    const els = document.elementsFromPoint(10, 80);

    if (window.scrollY <= 0) {
      this.header && this.header.current.classList.remove("visible");
      this.header && this.header.current.classList.remove("hidden");
    } else if (window.scrollY > this.scrollY) {
      this.header && this.header.current.classList.remove("visible");
      this.header && this.header.current.classList.add("hidden");
    } else {
      this.header && this.header.current.classList.remove("hidden");
      this.header && this.header.current.classList.add("visible");
    }

    this.scrollY = window.scrollY;

    let index = 1;
    let color;
    let inverse = false;

    while (els.length > index) {
      const el = els[index];

      if (el.classList.contains("header-color--dark")) {
        inverse = false;
        break;
      } else if (el.classList.contains("header-color--light")) {
        inverse = true;
        break;
      }

      color = el.style.backgroundColor;

      if (color) {
        this.backgroundEl = el;
        const matches =
          (color === "white" && [0, 255, 255, 255]) || color.match(colorRegex);
        if (matches) {
          const yiq =
            (matches[1] * 299 + matches[2] * 587 + matches[3] * 114) / 1000;
          if (yiq < 160) {
            inverse = true;
          } else {
            inverse = false;
          }
        }

        break;
      }

      index++;
    }

    if (inverse !== this.state.inverse) {
      this.setState({ inverse });
    }
    if (this.maskEnabled) {
      this.updateMask();
    }
  };

  onMenuClick = () => {
    this.setState(
      {
        showNav: true,
      },
      () => {
        setTimeout(this.onSvgTransitionEnd, 400);
      }
    );
  };

  onNavClose = () => {
    this.setState(
      {
        showNav: false,
        navOpened: false,
      },
      () => {
        this.collapseCircle();
      }
    );
  };

  onSvgTransitionEnd = () => {
    const { showNav } = this.state;
    if (!showNav) {
      return;
    }

    //const sideNav = document.querySelector(".side-nav__open-button");
    //sideNav.style.color = "white";
    this.expandCircle();
  };

  expandCircle = () => {
    const vw = window.innerWidth;
    const vh = window.innerHeight;
    let circle = document.querySelector(".menu-icon__circle");

    let squareSide = Math.max(vw, vh);
    const squareDiagonal = Math.sqrt(
      squareSide * squareSide + squareSide * squareSide
    );

    document.body.style.overflow = "hidden";

    circle.style.transform = `translate(50%, -50%) scale(${(2 *
      squareDiagonal) /
      circle.offsetWidth})`;

    setTimeout(this.onCircleTransitionEnd, 200);
  };

  collapseCircle = () => {
    let circle = document.querySelector(".menu-icon__circle");

    document.body.style.overflow = null;
    circle.style.transform = null;

    //const sideNav = document.querySelector(".side-nav__open-button");
    //sideNav.style.color = null;
  };

  onCircleTransitionEnd = () => {
    const { navOpened, showNav } = this.state;

    if (showNav && !navOpened) {
      this.setState({
        navOpened: true,
      });
    } else {
      let menuIconSvg = document.querySelector(".menu-icon svg");
      menuIconSvg.parentNode.classList.remove("menu-icon--clicked");
    }
  };

  render() {
    const { navOpened, showNav, inverse } = this.state;
    const { className } = this.props;

    return (
      <>
        <header
          className={classnames(
            {
              "cursor-color": showNav,
              "cursor-color--white": showNav,
              "cursor-type": showNav,
              "cursor-type--none": showNav,
              inverse,
            },
            className
          )}
          ref={this.header}
          role="presentation"
        >
          <FadeLink to="/" className="logo">
            <Logo />
          </FadeLink>

          <button
            className={classnames(
              "menu-icon",
              "cursor-type",
              "cursor-type--anchor",
              {
                "menu-icon--clicked": showNav,
                "cursor-type--none": showNav,
              }
            )}
            onClick={this.onMenuClick}
            tabIndex="0"
            onKeyDown={noop}
          >
            <MenuIcon />

            <div className="menu-icon__circle-wrapper"></div>

            <div
              className="menu-icon__circle"
              //onTransitionEnd={this.onCircleTransitionEnd}
            ></div>
          </button>
        </header>

        <Nav open={navOpened} onClose={this.onNavClose} />
      </>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  cursor: PropTypes.object,
};

Header.defaultProps = {
  siteTitle: "",
};

export default Header;
