import React from "react";
import classnames from "classnames";
import { Link } from "gatsby";
import Write404 from "./Write404";

import "./style.css";

const WarningBanner = props => {
  const { ie11 } = props;

  return (
    <div
      className={classnames("warning-banner header-color--light", {
        ie11: ie11,
      })}
    >
      <div className="warning-banner__text">
        {ie11 ? "<h1>Sorry!</h1>" : <Write404 />}
        {ie11 ? (
          <>
            <h3>Sorry!</h3>
            <p>
              Our site is not supported on IE. We still want you to visit us so
              please use Chrome, Edge, Safari or Firefox.
            </p>
            <svg
              width="30"
              height="40"
              viewBox="0 0 30 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9398 23.4029C12.0401 23.4029 12.107 23.4029 12.2074 23.4029C18.9298 23.435 24.4147 18.1701 24.4147 11.7175C24.4147 5.26485 18.9298 0 12.2074 0C5.48495 0 0 5.26485 0 11.7175C0 11.8138 0 11.878 0 11.9743V40.0321H29.2308L11.9398 23.4029ZM3.34448 11.7175C3.34448 7.0305 7.32441 3.21027 12.2074 3.21027C17.0903 3.21027 21.0702 7.0305 21.0702 11.7175C21.0702 16.4045 17.0903 20.2247 12.2074 20.2247C10.0669 20.2247 8.12709 19.4864 6.58863 18.2986L5.35117 17.0787C4.11371 15.634 3.34448 13.74 3.34448 11.7175ZM3.34448 19.7432C3.64548 20.0642 3.97993 20.3531 4.31438 20.61L21.1706 36.7897H3.34448V19.7432Z"
                fill="white"
              />
            </svg>
          </>
        ) : (
          <>
            <p>
              We couldn't find this page, just like you wouldn't find{" "}
              <strong>pineapple on our pizza</strong>
            </p>
            <Link href="/">Back home</Link>
          </>
        )}
      </div>
    </div>
  );
};

export default WarningBanner;
