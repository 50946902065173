/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";

import Layout from "@components/Layout";
import "@styles/normalize.css";
import "@styles/global.css";

import "@styles/fonts.css";
import "@styles/typography.css";

/* const updateFavicon = isDarkMode => {
  const el = document.querySelector('link[rel="icon"]');
  el.href = isDarkMode ? "/fav-white.png" : "/icons/icon-48x48.png";
}; */

const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

// Load the polyfill only under IE
const onClientEntry = () => {
  if (window.document.documentMode) {
    require("ie11-custom-properties");
    require("element-closest-polyfill");
    require("objectFitPolyfill");
  }

  /* const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  darkModeMediaQuery.addListener(event => updateFavicon(event.matches));
  if (darkModeMediaQuery.matches) {
    updateFavicon(true);
  } */
};

export { wrapPageElement, onClientEntry };
