import React, { useState } from "react";
import TheForm from "./TheForm";
import ResponseForm from "@components/ResponseForm";

function FormBusiness() {
  const [formSentState, setFormSentState] = useState(false);
  const [formMessage, setFormMessage] = useState({
    message: "",
    title: "",
    status: "",
  });

  const handleFormSent = () => {
    setFormSentState(true);
    setFormMessage({
      title: "Thank you!",
      message:
        "Your message has been sent. Someone at Roll Studio will reply to you very soon.",
      status: "success",
    });
  };

  const handleErrorForm = () => {
    setFormSentState(true);
    setFormMessage({
      title: "Ops!",
      message: "We are sorry, but something went wrong. Please, try again.",
      status: "error",
    });
  };

  return (
    <>
      {formSentState === false ? (
        <TheForm onSuccess={handleFormSent} onError={handleErrorForm} />
      ) : (
        <ResponseForm messageResponse={formMessage} />
      )}
    </>
  );
}

export default FormBusiness;
