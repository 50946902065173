const getForecast = apiFetch => async () => {
  try {
    const response = await apiFetch({
      endpoint: "forecast",
      method: "get",
      okStatus: 200,
    });
    return await response.json();
  } catch {
    return [];
  }
};

getForecast.propTypes = {};

export default getForecast;
