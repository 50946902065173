import { useStaticQuery, graphql } from "gatsby";
import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import "./style.css";

const Recaptcha = ({ onReady = f => f }, recaptchaRef) => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        rollStudioOptions {
          acf {
            recaptchaKey
          }
        }
      }
    }
  `);
  const acf = data?.wpgraphql?.rollStudioOptions?.acf;
  const recaptchaKey = acf?.recaptchaKey ?? "";

  const onRecaptchaChange = response => {
    // console.log("recaptcha loaded", response);
  };

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={recaptchaKey}
        onChange={onRecaptchaChange}
        asyncScriptOnLoad={onReady}
        className="form-recaptcha"
        badge="inline"
      />

      <p>
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy" target="_blank">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a href="https://policies.google.com/terms" target="_blank">
          Terms of Service
        </a>{" "}
        apply.
      </p>
    </>
  );
};

export default React.forwardRef(Recaptcha);
