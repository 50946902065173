import PropTypes from "prop-types";

const sendMail = apiFetch => async ({ name, email, message, recaptcha }) => {
  try {
    var res = await apiFetch({
      endpoint: "simpleform",
      method: "post",
      body: JSON.stringify({ name, email, message, recaptcha }),
      okStatus: 200,
      headers: {
        "Content-Type": "application/json",
      },
    });

    return res.json();
  } catch {
    return false;
  }
};

sendMail.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  message: PropTypes.string,
  recaptcha: PropTypes.string,
};

export default sendMail;
