const getLatestTweets = apiFetch => async () => {
  try {
    const response = await apiFetch({
      endpoint: "tweets",
      method: "get",
      okStatus: 200,
    });
    return await response.json();
  } catch {
    return [];
  }
};

getLatestTweets.propTypes = {};

export default getLatestTweets;
