import React from "react";

import woman1 from "./img/woman1.png";
import man1 from "./img/man1.png";
import woman2 from "./img/woman2.png";

function Write404() {
  var Pix = [woman1, man1, woman2];
  var randomNum = Math.floor(Math.random() * Pix.length);
  var randomPix = Pix[randomNum];
  return (
    <div className="message404">
      <span>4</span>
      <img src={randomPix} alt="404" />
      <span>4</span>
    </div>
  );
}
export default Write404;
