import classnames from "classnames";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";

import "./style.css";

const Form = ({ children, className, onSubmit, ...props }) => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form
        className={classnames("form", className)}
        onSubmit={methods.handleSubmit(onSubmit)}
        {...props}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
