import classnames from "classnames";
import React, { useMemo } from "react";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import "./style.css";

const defaultErrorMessages = {
  required: "Required field.",
  other: "Invalid field.",
};

const SelectField = ({
  name,
  label,
  required,
  className,
  options,
  defaultValue,
  errorMessages,
  ...props
}) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const inputProps = register(name, {
    required,
    defaultValue,
  });

  const error = useMemo(() => {
    const messages = { ...defaultErrorMessages, ...errorMessages };
    if (errors[name]) {
      return messages[errors[name].type] || messages.other;
    }

    return null;
  }, [errors, name, defaultErrorMessages, errorMessages]);

  console.log(options[0].value);

  const selectStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "none",
      color: state.isFocused ? "#ec5d57" : "#fff",
      fontSize: "3rem",
      padding: "1.2rem",
      backgroundColor: state.isFocused ? "#fff" : "transparent",
    }),
    container: provided => ({
      ...provided,
      width: "100%",
      padding: "0",
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "1.2rem 0",
      fontSize: "3rem",
    }),
    input: provided => ({
      ...provided,
      color: "white",
      fontSize: "3rem",
      padding: "0",
    }),
    control: provided => ({
      ...provided,
      fontSize: "3rem",
      backgroundColor: "transparent",
      border: "none",
      borderBottom: "1px solid white",
      borderRadius: 0,
      boxShadow: "none",
      "&:hover": {
        borderColor: "white",
      },
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "3rem",
      color: "white",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "white",
      transform: state.selectProps.menuIsOpen
        ? "rotate(180deg)"
        : "rotate(0deg)",
      transition: "transform 0.2s",
    }),
    menu: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: "#ec5d57",
      color: "white",
      fontSize: "3rem",
      position: "relative",
      boxShadow: "none",
      marginTop: "0",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menuList: (provided, state) => ({
      ...provided,
      fontSize: "3rem",
      maxHeight: "100%",
      //animate slide
      borderBottom: "1px solid white",
      //overflow: "hidden",
    }),
    singleValue: provided => ({
      ...provided,
      color: "white",
      fontSize: "3rem",
    }),
  };

  return (
    <div className={classnames("select-field", className)}>
      <label className="select-field__label" htmlFor={name}>
        {label}
      </label>

      <Controller
        render={({ field: { onChange } }) => (
          <Select
            {...props}
            {...inputProps}
            styles={selectStyles}
            options={options}
            defaultInputValue={options[0].value}
            onChange={value => {
              onChange(value.value);
            }}
          />
        )}
        name={name}
        control={control}
      />

      {error && <div className="select-field__error">{error}</div>}
    </div>
  );
};

export default SelectField;
