import React, { useContext } from "react";
import ModalContext from "@components/ModalContext";
import "./style.css";

function ResponseForm({ messageResponse }) {
  const modal = useContext(ModalContext);

  const closeModal = () => {
    modal.setIsOpen({ isOpen: false, typeForm: "" });
  };

  return (
    <div className="responseMessage">
      <div className="responseMessage_content">
        <h1>{messageResponse.title}</h1>
        <p>{messageResponse.message}</p>
        {messageResponse.status === "success" ? (
          <button onClick={closeModal}>Close</button>
        ) : (
          <button onClick={closeModal}>Back</button>
        )}
      </div>
    </div>
  );
}

export default ResponseForm;
