import apiFetch from "@api/fetch";

import apiSendMail from "@api/sendMail";
import apiSendBusinessSubmission from "@api/sendBusinessSubmission";
import apiSendJobSubmission from "@api/sendJobSubmission";
import apiGetLatestTweets from "@api/getLatestTweets";
import apiGetLatestInstagrams from "@api/getLatestInstagrams";
import apiSubscribe from "@api/subscribe";
import apiGetForecast from "@api/getForecast";

export const sendMail = apiSendMail(apiFetch);
export const sendBusinessSubmission = apiSendBusinessSubmission(apiFetch);
export const sendJobSubmission = apiSendJobSubmission(apiFetch);
export const getLatestTweets = apiGetLatestTweets(apiFetch);
export const getLatestInstagrams = apiGetLatestInstagrams(apiFetch);
export const subscribe = apiSubscribe(apiFetch);
export const getForecast = apiGetForecast(apiFetch);
