import React, { useContext } from "react";

import ModalContext from "@components/ModalContext";
import { ReactComponent as Logo } from "@assets/images/r-symbol.svg";
import Grid from "@components/Grid";

import "./style.css";

function Modal({ children, isOpen }) {
  const modal = useContext(ModalContext);

  const closeModal = () => {
    modal.setIsOpen({ isOpen: false, typeForm: "" });
  };

  return (
    <>
      <div
        className={`modal ${
          isOpen.isOpen ? "opened" : ""
        } cursor-color cursor-color--white`}
      >
        <div className="modal__header">
          <div className="logo-r">
            <Logo />
          </div>
          <button onClick={closeModal} className="close-modal">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
              <path
                d="M33 1L1 33M33 1L1 33M1 1l32 32M1 1l32 32"
                stroke="#fff"
              ></path>
            </svg>
          </button>
        </div>
        <Grid.Container fluid>
          <Grid.Row>
            <Grid.Cell
              mobileColumns={4}
              columns={6}
              offset={3}
              className="cell"
            >
              {children}
            </Grid.Cell>
          </Grid.Row>
        </Grid.Container>
      </div>
    </>
  );
}

export default Modal;
